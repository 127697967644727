@charset "utf-8";
.pei_flex{
  display: flex;
  align-items: center;
  column-gap: 12px;

}
.pei_border{
  background-color: #efefef;
  border-radius: 20%;
  width:24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pei_listwidth_active{
border:1px solid;
border-radius: 50%;
width:32px;
height: 32px;

}
.pei_listwidth{
  display: flex;
  justify-content: center;
  align-items: center;
  width:32px;
}
.pei_cursor{
  cursor: pointer;
}
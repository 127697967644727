@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css");

* {
  word-break: keep-all;
}

/* additional style */
.bgCover {
  background-size: cover !important;
}

.bgSize {
  background-size: contain !important;
  overflow: hidden;
}

.ellipsis {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ellipsis2 {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ellipsis3 {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ellipsis5 {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* spinner */
.loader {
  border-top-color: #417fff;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

/* datepicker custom */
.react-datepicker {
  border: none !important;
  color: #463f38 !important;
  width: 100vw !important;
}

.react-datepicker__header {
  display: none;
}

.react-datepicker__month-container {
  width: 100%;
  background-color: #fff !important;
  border: none !important;
  padding: 0 16px 8px;
}

.react-datepicker__month {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.react-datepicker__day-names {
  display: none;
}

.react-datepicker__week {
  width: full;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 0;
}

/* .react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__month--selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 4px !important;
  background-color: #fcfbf2 !important;
  color: #463f38 !important;
} */

.react-datepicker__day,
.react-datepicker__day-name {
  position: relative;
  text-align: center;
  align-self: center;
  color: #0d0d0d;
  display: inline-block;
  width: 40px !important;
  height: 40px !important;
  line-height: 20px !important;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 600;
  margin: 0 !important;
  padding-top: 9px;
}

.react-datepicker__day--highlighted {
  background-color: transparent !important;
  color: #000000 !important;
}

.react-datepicker__day--outside-month {
  color: #aeaeae !important;
}

.react-datepicker__day--highlighted::after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: calc(50% - 2px);
  /* top: calc(80% - 2px); */
  bottom: 5px;
  border-radius: 100%;
}

.기록완료 .react-datepicker__day--highlighted::after {
  background-color: #417fff !important;
}
.식사기록 .react-datepicker__day--highlighted::after {
  background-color: #fe718e !important;
}
.체중기록 .react-datepicker__day--highlighted::after {
  background-color: #ffc700 !important;
}
.증상기록 .react-datepicker__day--highlighted::after {
  background-color: #847ce9 !important;
}
.복약알림 .react-datepicker__day--highlighted::after {
  background-color: #01b68b !important;
}
.진료알림 .react-datepicker__day--highlighted::after {
  background-color: #51ccf3 !important;
}
.걸음수 .react-datepicker__day--highlighted::after {
  background-color: #fe718e !important;
}

.react-datepicker__day--selected.react-datepicker__day--highlighted::after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: calc(50% - 2px);
  bottom: 5px;
  background-color: #ffffff !important;
  border-radius: 100%;
}

.react-datepicker__day--today {
  background-color: #dde8ff !important;
  border-radius: 50% !important;
  background-color: transparent;
}

.react-datepicker__day--selected {
  position: relative;
  text-align: center;
  align-self: center;
  display: inline-block;
  width: 40px !important;
  height: 40px !important;
  line-height: 20px !important;
  font-size: 14px;
  font-weight: 600;
  margin: 0 !important;
  padding-top: 9px;
  background-color: #417fff !important;
  border-radius: 50% !important;
  color: #ffffff !important;
}

/* swiper custom */
.swiper-pagination {
  background-color: #fefefe;
}

.swiper-pagination-bullet {
  width: 14px !important;
  height: 14px !important;
  background: #dfdfdf !important;
}

.swiper-pagination-bullet-active {
  background: #242424 !important;
}

/* 바디에 스크롤 막기 */
.no-scroll {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 10;
}

/* .no_scroll .cont {
  position: relative;
  top: 0;
} */

/* 스크롤바 숨기기 */
body::-webkit-scrollbar {
  display: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* toast custom */
.Toastify__toast {
  min-height: auto !important;
  padding: 8px 15px !important;
  margin-bottom: 4rem !important;
}

.Toastify__toast-container {
  text-align: center;
}

.Toastify__close-button {
  display: ruby-base;
}

.toast_alert {
  display: inline-flex;
  background-color: #b2cbff;
  border-radius: 28px;
  color: #180f06;
  font-size: 14px;
  font-weight: 600;
}

.ellipsisTable {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ellipsisTable:hover {
  display: initial;
  -webkit-line-clamp: initial;
  -webkit-box-orient: initial;
  overflow: initial;
}

@media only screen and (min-width: 429px) {
  .maxW {
    width: 375px;
  }
}

/* input(checkbox) -/tutorial */
input[type="checkbox"].tutorial {
  appearance: none;
  width: 24px;
  height: 24px;
  background-image: url("../public/images/mobile/icon/checkbox.svg");
}

input[type="checkbox"].tutorial:checked {
  background-image: url("../public/images/mobile/icon/checkbox_checked.svg");
}

input[type="radio"].info {
  appearance: none;
  width: 28px;
  height: 28px;
  background-image: url("../public/images/mobile/icon/checkbox2.svg");
}

input[type="radio"].info:checked {
  appearance: none;
  width: 28px;
  height: 28px;
  background-image: url("../public/images/mobile/icon/checkbox2_checked.svg");
}

/* input(checkbox) - /home (survey) */
input[type="checkbox"].survey {
  appearance: none;
  width: 28px;
  height: 28px;
  background-image: url("../public/images/mobile/icon/checkbox2.svg");
}

input[type="checkbox"].survey:checked {
  appearance: none;
  width: 28px;
  height: 28px;
  background-image: url("../public/images/mobile/icon/checkbox2_checked.svg");
}

/* input(radio) - /myinfo/edit*/
input[type="checkbox"].myinfoeditgender {
  appearance: none;
  width: 28px;
  height: 28px;
  background-image: url("../public/images/mobile/icon/radio_green_off.svg");
}

input[type="checkbox"].myinfoeditgender:checked {
  background-image: url("../public/images/mobile/icon/radio_green.svg");
}

/*dashedBottom - /myinfo 대쉬 */
.dashedBottom {
  border-bottom: 1px dashed #66cd79;
}

/*togglebtn*/
.toggle {
  position: relative;
  cursor: pointer;
}

.toggle .toggle-container {
  width: 44px;
  height: 24px;
  border-radius: 30px;
  background-color: #bab7b5;
  transition: all 0.2s ease;
}

.toggle .toggle-container {
  background-color: #61be82;
}

.toggle .toggle-circle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fafafa;
  transition: all 0.25s ease;
}

.toggle .toggle-circle.toggle--checked {
  left: 24px;
}

/*apexchart*/
#lineGraph .apexcharts-legend {
  display: none;
}

.bgTail {
  background-image: url("../public/images/mobile/icon/reverse_triangle.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

#chart .apexcharts-tooltip {
  border: none !important;
}

/* chatgpt loading animation */
.gptloading {
  /* width: 100vw; */
  /* margin-top: 100px; */
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
}

.gptloading span {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: gray;
  border-radius: 50%;
  animation: gptloading 1s linear infinite;
  background-color: black;
}

.gptloading span:nth-child(1) {
  animation-delay: 0s;
}

.gptloading span:nth-child(2) {
  animation-delay: 0.2s;
  margin: 0px 10px;
}

.gptloading span:nth-child(3) {
  animation-delay: 0.4s;
}

.ql-container.ql-disabled {
  border: transparent 0px solid !important;
}

.ql-readonly {
  .ql-editor {
    padding: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    & > p > img {
      margin-bottom: 30px;
    }
  }
}

@keyframes gptloading {
  0%,
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

/* spinner */
.loader {
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee {
  display: inline-block;
  animation-name: marquee;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

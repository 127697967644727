.landing-swiper {
  width: 100%;
  padding-bottom: 50px;
}

.landing-swiper-pagination {
  height: 30px;
  display: flex;
  gap: 7px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.mobile-landing-swiper-pagination {
  height: 50px;
  display: flex;
  gap: 7px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.landing-swiper-bullet {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #d9d9d9;
  opacity: 0.5;
}

.mobile-landing-swiper-bullet {
  height: 4px;
  width: 4px;
  border-radius: 100%;
  background-color: #d9d9d9;
  opacity: 0.5;
}

.landing-swiper-bullet-active {
  background-color: #1b5edc;
  opacity: 1;
}

.mobile-landing-swiper-bullet-active {
  background-color: #1b5edc;
  opacity: 1;
}

/* 네비게이션 버튼의 크기와 색상 변경 */
.swiper-button-next,
.swiper-button-prev {
  color: #8b8b8b; /* 화살표 색상 변경 */
  width: 0px; /* 화살표 너비 변경 */
  height: 30px; /* 화살표 높이 변경 */
}

/* 화살표 아이콘(실제로는 ::after 가상 요소에 스타일을 적용) 크기 변경 */
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 25px; /* 아이콘 크기 변경 */
  padding: 80px; /* 클릭 가능한 범위 확장 */
  z-index: -1;
}

/* transform 50% */
.transform-50 {
  transform: translate(-50%, -50%);
}

.transform-x-50 {
  transform: translateX(-50%);
}

.transform-y-50 {
  transform: translateY(-50%);
}

/*wordbreak*/
.keepall {
  word-break: keep-all;
}

/*managementCheck*/
.managementCheck .checkbox {
  appearance: none;
  display: none;
  background-color: transparent;
}

.managementCheck .checkbox:checked + label {
  border-color: transparent;
  background-repeat: no-repeat;
  background-color: rgb(24, 24, 25);
  background-image: url("../public/images/admin/icon/check.svg");
  background-position: left 10% center;
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding-left: 32px;
  padding-right: 16px;
}

/*contentsRadio*/
.contentsRadio .radio {
  appearance: none;
  display: none;
  background-color: transparent;
}

.contentsRadio .radio:checked + label span {
  border-color: transparent;
  background-repeat: no-repeat;
  background-color: #fa8f69;
  background-image: url("../public/images/admin/icon/check.svg");
  background-position: center;
}
/*togglebtn*/
.toggle {
  position: relative;
  cursor: pointer;
}

.toggle .toggle-container {
  width: 44px;
  height: 24px;
  border-radius: 30px;
  background-color: #dfdfdf;
  transition: all 0.2s ease;
}

.toggle .toggle-container.toggle--checked {
  background-color: #61be82;
}

.toggle .toggle-circle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fafafa;
  transition: all 0.25s ease;
}

.toggle .toggle-circle.toggle--checked {
  left: 24px;
}

/*checkbox*/
.signupCheckbox input[type="checkbox"] {
  appearance: none;
  width: 24px;
  height: 24px;
  border-color: transparent;
  background-repeat: no-repeat;
  background-image: url("../public/images/admin/icon/checkbox_off.svg");
  background-size: 24px 24px;
}
.signupCheckbox input[type="checkbox"]:checked {
  width: 24px;
  height: 24px;
  border-color: transparent;
  background-repeat: no-repeat;
  background-image: url("../public/images/admin/icon/checkbox_on.svg");
  background-size: 24px 24px;
}

/* pagination */
.pagination {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.pagination li {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .active {
  border-radius: 50%;
  border: 1px solid #180f06;
}

/* 그래프 tooltip */
.apexcharts-tooltip {
  position: absolute;
  top: -7% !important;
  left: 20%;
  padding: 3px 8px;
  box-shadow: none !important;
  border-radius: 8px !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
  border: solid 1px #c4c4c4 !important;
  padding: 5px 8px !important;
  overflow: visible !important;
}

/* Admin 그래프 tooltip + bar-hover-color */
.admin-graphs .apexcharts-tooltip {
  transform: translateY(75%);
}

.admin-graphs #yellow_h .apexcharts-bar-area:hover,
.admin-graphs #yellow_h .apexcharts-bar-area:active {
  fill: #fac232;
}

.admin-graphs #blue_h .apexcharts-bar-area:hover,
.admin-graphs #blue_h .apexcharts-bar-area:hover {
  fill: #22b8e8;
}

.admin-graphs #gray_h .apexcharts-bar-area:hover,
.admin-graphs #gray_h .apexcharts-bar-area:active {
  fill: #746f6a;
}

.admin-graphs #red_h .apexcharts-bar-area:hover,
.admin-graphs #red_h .apexcharts-bar-area:active {
  fill: #e1815e;
}

.admin-graphs #green_h .apexcharts-bar-area:hover,
.admin-graphs #green_h .apexcharts-bar-area:active {
  fill: #57ab75;
}

/*customTooltip*/
.customToolTip{
  opacity: 0;
  transition-timing-function: linear;
  transition-duration: 100ms;
}
.customTooltipLayout:hover .customToolTip{
  opacity: 100%;
  transition-timing-function: linear;
  transition-duration: 100ms;
}

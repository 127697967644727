@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700;900&display=swap");

@font-face {
  font-family: "Pretendard";
  src: url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css");
}

@font-face {
  font-family: "ONE-Mobile-Title";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/ONE-Mobile-Title.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
    font-family: 'ONE-Mobile-Regular';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/ONE-Mobile-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
} */

@font-face {
  font-family: "yg-jalnan";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NanumSquareNeo-Variable";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/NanumSquareNeo-Variable.woff2")
    format("woff2");
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* toastui */

/* body1/500L */
.toastui-editor-contents p {
  font-weight: 500 !important;
  font-size: 22px !important;
  line-height: 34px !important;
  letter-spacing: 1.5% !important;
}
/* caption1/400 */
.toastui-editor-contents h6 {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 1.5% !important;
}
/* body3/500 */
.toastui-editor-contents h5 {
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  letter-spacing: 1.5% !important;
}
/* body2/500L */
.toastui-editor-contents h4 {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 32px !important;
  letter-spacing: 1.5% !important;
}
/* body1/500L */
.toastui-editor-contents h3 {
  font-weight: 500 !important;
  font-size: 22px !important;
  line-height: 34px !important;
  letter-spacing: 1.5% !important;
}
/* subtitle3 */
.toastui-editor-contents h2 {
  font-weight: 500 !important;
  font-size: 22px !important;
  line-height: 30px !important;
  letter-spacing: 1.5% !important;
  border: none !important;
}
/* subtitle2 */
.toastui-editor-contents h1 {
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 34px !important;
  letter-spacing: 1.5% !important;
  border: none !important;
}

/* #viewer .toastui-editor-contents h1, 
  #viewer .toastui-editor-contents h2,
  #viewer .toastui-editor-contents h3,
  #viewer .toastui-editor-contents h4,
  #viewer .toastui-editor-contents h5,
  #viewer .toastui-editor-contents h6
  {
    display: contents;
  } */

#viewer .toastui-editor-contents p img,
#viewer .toastui-editor-contents h4 img {
  display: flex !important ;
  justify-content: center !important;
  margin: 0 auto;
}

@media (max-width: 385px) {
  .ssm\:p-4 {
    padding: 1rem;
  }
  /* 추가적으로 적용하고 싶은 스타일을 정의하세요 */
}
